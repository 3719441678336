import React, { useState, Fragment } from 'react'
import Img from "gatsby-image"
import { Link } from "gatsby"

import flatten from "lodash/fp/flatten"
import uniqBy from "lodash/fp/uniqBy"
import flow from "lodash/fp/flow"

import { css } from '@emotion/core'
import { constants } from '../styles/colors'

import { MdPlaylistPlay, MdBeenhere } from "react-icons/md"
import LangSelector from './langSelector'
import { getLanguages, translateKey } from '../helpers/helpers'
import { i18nStrings } from '../helpers/translations'

const Card = (props) => {
    const {
        // slug,
        // slug_fr,
        categories,
        // name,
        // name_fr,
        desc,
        desc_fr,
        list,
    } = props

    // Test to see how many languages exist for this card
    const languages = getLanguages(props)

    // Avoir un state au niveau de la card pour passer FR ou EN 
    // TODO: Ce serait bien par le futur de remplacer le défault par une préférence site-wide en cookies
    const [cardLang, setCardLang] = useState(languages[0])

    // Get thumbnail if it exists
    const item_with_thumbnail = list.filter( item => item.featured === true )
    const featured_img = item_with_thumbnail.length > 0 ? item_with_thumbnail[0].img.childImageSharp.fixed : null

    // Get unique curators if they exist
    const curatorsList = list.map( item => item.curators ).filter( item => item !== null )
    const uniqueCurators = curatorsList.length > 0 ? flow(flatten,uniqBy('id'))(curatorsList) : null

    return (
        <div css={cardContent}>
            <div css={header}>
                <div>
                    {categories && <div css={cardCategories}>{categories.map(cat => <span key={cat}>#{cat}</span>)}</div>}
                    <h2><Link to={`/${cardLang}/${props[translateKey(cardLang, 'slug')]}`}>Top {list.length} {props[translateKey(cardLang, 'name')]}</Link></h2>
                    
                </div>
                {featured_img && <Img fixed={featured_img} />}
            </div>

            <div css={main}>
                {(desc || desc_fr) && <p>{props[translateKey(cardLang, 'desc')].slice(0, 105).concat(" ...")}</p>}
                {uniqueCurators && 
                    <div css={curatorsBox}>
                        <span><MdBeenhere /> {i18nStrings.byCurators[cardLang]}</span>
                        {uniqueCurators.map( curator => (
                            <Fragment key={curator.name}>
                                <span className='curator__name'>{curator.name}</span>
                                <Img fixed={curator.avatar.childImageSharp.fixed} />
                            </Fragment>
                        ))}
                    </div>
                }
            </div>

            <div css={footer}>
                <Link to={`/${cardLang}/${props[translateKey(cardLang, 'slug')]}`}>
                    <MdPlaylistPlay />
                    {i18nStrings.card_link[cardLang][0]} {list.length} {i18nStrings.card_link[cardLang][1]}
                </Link>
                {languages.length > 1 &&     
                    <div css={cardOptions}>
                        <LangSelector languages={languages} setCardLang={setCardLang} cardLang={cardLang} />
                    </div>   
                }
            </div>
        </div>
    )
}

export default Card

const cardContent = css`
    width: 48%;
    position: relative;
    background-color: white;
    border-radius: ${constants.radiusBig};
    box-shadow: ${constants.shadowBig};
    padding: 10px 0 10px;
    margin-bottom: 40px;
    
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media screen and (max-width: ${constants.breakpoint1}) {
        width: 100%;
    } 
`

const header = css`
    padding: 0 20px 0;
    display: flex;
    justify-content: space-between;
    overflow: visible;
    margin: 0;

    h2 {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        padding-right: 20px;
        line-height: 1.3;

        a {
            text-decoration: none;
            color: inherit;
        }
    }

    > div {
        width: 50%;
        flex-grow: 1;
        flex-basis: auto;
    }

    .gatsby-image-wrapper {
        margin-top: -20px;
        margin-right: -10px;
        box-shadow: ${constants.shadowSmall};
        border-radius: ${constants.radiusSmall};
        
        img {
            border-radius: ${constants.radiusSmall};
            margin: 0;
            // border: 2px solid ${constants.whitegrey};
        }
    }
`

const main = css`
    padding: 10px 20px 0;

    p {
        margin-bottom: 0px;
        color: ${constants.midgrey};
        font-size: 14px;
        line-height: 1.3;
    }
`

const cardCategories = css`
  margin-bottom: 10px;
  height: 32px;
  overflow-y: hidden;
  padding-right: 10px;

  span {
    margin-right: 6px;
    display: inline-block;
    padding: 3px 12px;
    background-color: ${constants.whitegrey};
    border-radius: ${constants.radiusMax};
    font-size: 12px;
    text-transform: lowercase;
  }
`

const footer = css`
  font-size: 14px;
  margin-top: 10px;
  padding: 10px 20px 0;
  border-top: 1px solid ${constants.whitegrey};
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
      text-decoration: none;
      color: inherit;
      transition: color .3s ease-in-out;

      :active, :hover, :visited {
        text-decoration: none;
        color: inherit;
      }

        :hover {
            color: ${constants.accent};
        }

      svg {
        margin-bottom: -3px;
        margin-right: 10px;
        color: ${constants.accent};
      }
  }

`

const cardOptions = css`
    margin: 0 -10px 0 0;
`

const curatorsBox = css`
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 12px;
    color: ${constants.midgrey};

    svg {
        margin-bottom: -2px;
        color: ${constants.accent2};
    }
    
    span.curator__name {
        color: ${constants.darkgrey};
        margin-left: 7px;
        margin-right: 5px;
    }

    img {
        border-radius: ${constants.radiusMax};
        border: 1px solid ${constants.lightgrey};
        margin-right: 3px;
    }
`