import React, { useState } from 'react'

import { css } from '@emotion/core'
import { constants } from '../styles/colors'

import { MdMoreVert, MdClose } from "react-icons/md"

const LangSelector = ({languages, setCardLang, cardLang}) => {

    // Option box should have a state to become visible
    const [optionsVisible, setOptionsVisible] = useState(false)

    // console.log("Card lang is", cardLang)

    return (
        <div css={main}>
            <button 
                onClick={() => setOptionsVisible(prevVisibility => !prevVisibility)}
                aria-label="Select language"
            >
                {!optionsVisible ? <MdMoreVert /> : <MdClose />}
            </button>
            <div 
                style={{visibility: `${optionsVisible ? "visible" : "hidden"}`}}
                css={box}
            >
                {languages.map( lang => {
                    // console.log("Lang is ", lang)

                    return (
                    <button 
                        onClick={() => setCardLang(lang)} 
                        css={cardLang === lang && makeActive}
                        key={lang}
                    >
                        {lang}
                    </button>
                )})}
            </div>
        </div>
    )
}

export default LangSelector

const main = css`
    position: relative;
    
    button {
        background: ${constants.white};
        border: none;
        cursor: pointer;

        :focus {
            outline: none;
        }
    }

    > button {
        padding: 4;
        line-height: 1;
        color: ${constants.lightgrey};
    }
`

const box = css`
    position: absolute;
    top: -60px;
    right: -5px;
    border: 1px solid ${constants.lightgrey};
    padding: 5px 10px;
    background: ${constants.white};
    border-radius: ${constants.radiusSmall};

    button {
        font-size: 12px;
        padding: 0;
        line-height: 2;
        display: block;
        text-align: center;
        text-transform: uppercase;
        color: ${constants.midgrey};
    }
`

const makeActive = css`
    color: ${constants.darkgrey}!important;
    font-weight: 700;
`