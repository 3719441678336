import React from 'react'
import { graphql } from "gatsby"

import Layout from '../components/layout'
import SEO from '../components/seo'

import {css} from '@emotion/core'

import Card from '../components/card'

const IndexPage = (props) => {
  const lists = props.data.lists.edges.map(edge => edge.node)

  return (
  <Layout>
    <SEO title="Hiplyst - Home of Lists"/>

    <div className="container" style={{marginTop: '50px'}}>
      <div css={feedList}>
        {lists.map( list => {
          return (
              <Card key={list.slug || list.slug_fr} {...list} />
            )
          })}
      </div>
    </div>

  </Layout>
)}

export default IndexPage

/**
 * 
 * Queries 
 * - the Hero component
 * - 10 first lists
 * 
 */
export const IndexQuery = graphql`
  query IndexQuery {
    lists: allListsYaml(filter: {published: {eq: true}}) {
      edges {
        node {
          id
          name
          name_fr
          slug
          slug_fr
          desc
          desc_fr
          categories
          list {
            title
            title_fr
            featured
            curators {
              id
              name
              avatar {
                  childImageSharp {
                      fixed(width: 30, height: 30) {
                          ...GatsbyImageSharpFixed_withWebp_tracedSVG
                      }
                  }
              }
            }
            img {
              childImageSharp {
                  fixed(width: 180, height: 120) {
                      ...GatsbyImageSharpFixed_withWebp_tracedSVG
                  }
              }
            }
            brand {
              ...BrandFields
            }
          }
        }
      }
    }
  }
`

/**
 * 
 * CSS
 * 
 */

const feedList = css`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`